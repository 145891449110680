import { TooltipProps, Tooltip } from '@mui/material';
import React from 'react';

export type VibTooltipProps = TooltipProps & {
  active?: boolean;
};

export const VibTooltip = (props: VibTooltipProps) => {
  const { active = true } = props;

  return !active ? (
    props.children
  ) : (
    <Tooltip
      slotProps={{
        tooltip: { className: 'bg-primary/90' },
      }}
      enterTouchDelay={0}
      leaveTouchDelay={3000}
      {...props}
    >
      <div>{props.children}</div>
    </Tooltip>
  );
};
