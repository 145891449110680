import NextLink, { LinkProps } from 'next/link';
import { ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { Typography } from './Typography';

type Props = LinkProps &
  Partial<Pick<HTMLAnchorElement, 'id' | 'target'>> & {
    children?: ReactNode;
    className?: string;
    unstyled?: boolean;
    disabled?: boolean;
  };

export const Link = (props: Props) => {
  const { children, unstyled, ...restProps } = props;

  const linkStyles = unstyled
    ? 'no-underline text-inherit'
    : 'text-secondary underline transition hover:text-blue-600';

  const className = twMerge(linkStyles, restProps.className);

  if (props.disabled)
    return <Typography className={className}>{children}</Typography>;

  return (
    <NextLink {...restProps} className={className}>
      {children}
    </NextLink>
  );
};
