import { getGroups } from '@api/users';
import useToastContext from '@contexts/ToastContext/hook';
import { UserProduct, Groups, UserRole, UserRoles } from '@interfaces/auth';
import { Product } from '@interfaces/product';
import { uniqueGroupsMap } from '@utils/uniqueGroupsMap';
import { VibLoadingOverlay } from '@vibTheme/components/VibLoadingOverlay';
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

interface GroupsContextValue {
  groups: Groups;
  setGroups: Dispatch<SetStateAction<Groups>>;
  getAllProducts: () => UserProduct[];
  getAllProductsIds: () => number[];
  fetchGroups: () => Promise<void>;
  getUserRoleByProduct: (id: Product['id']) => UserRole;
  filterProductIdsByRole: (
    productIds: Array<Product['id']>,
    roles: UserRole[],
  ) => UserProduct['id'][];
}

export const GroupsContext = createContext({} as GroupsContextValue);

const groupsInitialState = {
  owner: [],
  coproducer: [],
  affiliated: [],
  referrer: [],
};

export function GroupsContextProvider({ children }: PropsWithChildren) {
  const [groups, setGroups] = useState<Groups>(groupsInitialState);
  const [isGroupStateReady, setIsGroupStateReady] = useState(false);
  const toast = useToastContext();

  const fetchGroups = async () => {
    try {
      const groups = await getGroups();
      setGroups(groups);
      setIsGroupStateReady(true);
    } catch {
      toast.setContent(
        'Ocorreu um erro ao buscar os dados de suas comunidades. Por favor, entre em contato com o suporte.',
        'error',
      );
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  if (!isGroupStateReady) return <VibLoadingOverlay />;

  function getAllProducts() {
    const allProducts = [
      ...groups.owner,
      ...groups.coproducer,
      ...groups.affiliated,
    ];
    return uniqueGroupsMap(allProducts);
  }

  function getAllProductsIds() {
    const allProducts = getAllProducts();
    return allProducts.map((product) => product.id);
  }

  function getUserRoleByProduct(id: Product['id']): UserRole {
    const areProductIdsEqual = (product: UserProduct) => product.id === id;

    if (groups.owner.find(areProductIdsEqual)) return 'OWNER';
    if (groups.coproducer.find(areProductIdsEqual)) return 'COPRODUCER';
    if (groups.affiliated.find(areProductIdsEqual)) return 'AFFILIATED';
    if (groups.referrer.find(areProductIdsEqual)) return 'REFERRER';

    return 'AFFILIATED';
  }

  function filterProductIdsByRole(
    productIds: Array<Product['id']>,
    roles: UserRole[],
  ) {
    const lowercasedRoles = roles.map((role) =>
      Object.values(UserRoles)
        .find((value) => value === role)
        ?.toLowerCase(),
    ) as Array<keyof Groups>;

    const groupIds = lowercasedRoles.map((role) =>
      productIds.filter((groupId) =>
        groups[role].find((group) => group.id === groupId),
      ),
    );

    const uniqueProductIds = [...new Set(groupIds.flat())];
    return uniqueProductIds;
  }

  return (
    <GroupsContext.Provider
      value={{
        groups,
        setGroups,
        getAllProducts,
        getAllProductsIds,
        fetchGroups,
        getUserRoleByProduct,
        filterProductIdsByRole,
      }}
    >
      {children}
    </GroupsContext.Provider>
  );
}
