import Head from 'next/head';
import '@vibTheme/globals.css';
import type { AppProps } from 'next/app';
import { ThemeProvider } from '@mui/material';
import muiTheme from '@vibTheme/mui-theme';
import { Layouts } from '@interfaces/layouts';
import Layout from '@components/Layout';
import LayoutAppbarOnly from '@components/Layout/AppbarOnly';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { SessionProvider, useSession, signIn } from 'next-auth/react';
import { ToastContextProvider } from '../contexts/ToastContext';
import { GroupsContextProvider } from '../contexts/GroupsContext';
import { poppins } from '@vibTheme/fonts';
import { PropsWithChildren, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { saveRefToLocalStorage } from '@utils/captureRef';
import { CurrentProductContextProvider } from '@contexts/CurrentProductContext';
import { FeaturePermissionsContextProvider } from '@contexts/FeaturePermissionsContext';
import { VibLoadingOverlay } from '@vibTheme/components/VibLoadingOverlay';
import { PageTypes } from '@interfaces/pageType';
import { AppCacheProvider } from '@mui/material-nextjs/v13-pagesRouter';
import { User2faContextProvider } from '@contexts/User2faContext';
import { TourContextProvider } from '@contexts/TourContext';

const cache = createCache({
  key: 'css',
  prepend: true,
});

export default function MyApp(props: AppProps) {
  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.NEXT_PUBLIC_GTM_ID ?? 'GTM-K268QQJ',
    });

    const urlParams = new URLSearchParams(window.location.search);
    const refValue = urlParams.get('referrerCode');
    if (refValue) saveRefToLocalStorage(refValue);
  }, []);

  return (
    <AppCacheProvider {...props}>
      <SessionProvider>
        {renderPage(props.Component, props.pageProps)}
      </SessionProvider>
    </AppCacheProvider>
  );
}

const PageWrapper = (props: PropsWithChildren) => (
  <>
    <Head>
      <title>VibX - Área do Criador</title>
    </Head>
    <CacheProvider value={cache}>
      <ThemeProvider theme={muiTheme}>
        <ToastContextProvider>
          <TourContextProvider>
            <style jsx global>
              {`
                :root {
                  --font-poppins: ${poppins.style.fontFamily};
                }
              `}
            </style>
            {props.children}
          </TourContextProvider>
        </ToastContextProvider>
      </ThemeProvider>
    </CacheProvider>
  </>
);

const PrivatePageWrapper = (props: PropsWithChildren) => {
  const session = useSession();
  if (session.status === 'unauthenticated') signIn();
  if (session.status !== 'authenticated') return <VibLoadingOverlay />;

  return (
    <PageWrapper>
      <GroupsContextProvider>
        <FeaturePermissionsContextProvider>
          <CurrentProductContextProvider>
            <User2faContextProvider>{props.children}</User2faContextProvider>
          </CurrentProductContextProvider>
        </FeaturePermissionsContextProvider>
      </GroupsContextProvider>
    </PageWrapper>
  );
};

const HybridPageWrapper = (props: PropsWithChildren) => {
  const session = useSession();

  switch (session.status) {
    case 'loading':
      return <VibLoadingOverlay />;

    case 'unauthenticated':
      return (
        <PageWrapper>
          <LayoutAppbarOnly>{props.children}</LayoutAppbarOnly>
        </PageWrapper>
      );

    case 'authenticated':
    default:
      return (
        <PrivatePageWrapper>
          <Layout>{props.children}</Layout>
        </PrivatePageWrapper>
      );
  }
};

const PublicPageWrapper = (props: PropsWithChildren) => {
  const session = useSession();
  if (session.status !== 'unauthenticated') return <VibLoadingOverlay />;

  return <PageWrapper>{props.children}</PageWrapper>;
};

const renderPage = (Component: any, pageProps: any) => {
  switch (Component.pageType) {
    case PageTypes.PUBLIC:
      return (
        <PublicPageWrapper>
          {renderPageWithLayout(Component, pageProps)}
        </PublicPageWrapper>
      );

    case PageTypes.HYBRID:
      return (
        <HybridPageWrapper>
          <Component {...pageProps} />
        </HybridPageWrapper>
      );

    case PageTypes.PRIVATE:
    default:
      return (
        <PrivatePageWrapper>
          {renderPageWithLayout(Component, pageProps)}
        </PrivatePageWrapper>
      );
  }
};

const renderPageWithLayout = (Component: any, pageProps: any) => {
  switch (Component.layout) {
    case Layouts.APPBARONLY:
      return (
        <LayoutAppbarOnly>
          <Component {...pageProps} />
        </LayoutAppbarOnly>
      );

    case Layouts.NONE:
      return <Component {...pageProps} />;

    case Layouts.DEFAULT:
    default:
      return (
        <Layout>
          <Component {...pageProps} />
        </Layout>
      );
  }
};
