import useGroupsContext from '@contexts/GroupsContext/hook';
import { useTourContext } from '@contexts/TourContext/hook';
import { Button } from '@vibTheme/components/_base/Button';
import { Image } from '@vibTheme/components/_base/Image';
import { Typography } from '@vibTheme/components/_base/Typography';
import { VibModal } from '@vibTheme/components/VibModal';
import React, { useEffect, useState } from 'react';

const WELCOME_DIALOG_KEY = 'isWelcomeCompleted';

export const WelcomeDialog = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { start: startTour } = useTourContext();

  const handleCompletion = async () => {
    typeof localStorage !== 'undefined' &&
      localStorage.setItem(WELCOME_DIALOG_KEY, 'true');
  };

  const handleConfirm = async () => {
    await startTour('productCreation');
    handleCompletion();
    setIsModalOpen(false);
  };

  const { groups } = useGroupsContext();
  useEffect(() => {
    const isWelcomeCompleted = localStorage.getItem(WELCOME_DIALOG_KEY);

    const hasNoGroups =
      !groups.affiliated.length &&
      !groups.coproducer.length &&
      !groups.owner.length;

    if (hasNoGroups && !isWelcomeCompleted) {
      setIsModalOpen(true);
      handleCompletion();
    }
  }, []);

  if (!isModalOpen) return null;

  return (
    <VibModal isOpen={isModalOpen} setIsOpen={setIsModalOpen}>
      <div className="max-w-[1000px] md:p-main">
        <div className="flex flex-col gap-main md:flex-row md:gap-[5rem]">
          <div className="relative h-[219px] md:w-[359px] md:h-[438px]">
            <Image src="/excited-girl.svg" alt="Garota animada!" fill />
          </div>

          <div className="md:w-[330px]">
            <Typography component="h1" className="pb-main">
              Seja bem-vindo(a) à VibX!
            </Typography>
            <Typography>
              Estamos felizes em te receber e prontos para te ajudar a
              impulsionar suas vendas pelo Telegram.
              <br />
              <br />
              Vamos começar juntos! Que tal cadastrar sua primeira comunidade
              agora?
            </Typography>
          </div>
        </div>

        <div className="pt-main text-right">
          <Button onClick={handleConfirm}>Começar</Button>
        </div>
      </div>
    </VibModal>
  );
};
