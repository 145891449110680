export const vibxConstants = {
  SUPPORT_WHATSAPP: 'https://wa.me/553183519296',
  SUPPORT_TELEGRAM: 'https://t.me/atendimentovibx3bot',
  INDICTMENT_LINK: 'https://app.vibx.com.br/indictment',
  SUPPORT_EMAIL: 'contato@vibx.com.br',
  INSTAGRAM_LINK: 'https://www.instagram.com/vibx.oficial/',
};

export const SUPPORT_WHATSAPP_NUMBER =
  '+' + vibxConstants.SUPPORT_WHATSAPP.split('/').pop();
export const SUPPORT_TELEGRAM_USERNAME =
  vibxConstants.SUPPORT_TELEGRAM.split('/').pop();
