import { CSSProperties, PropsWithChildren, ReactNode } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useTourContext } from '@contexts/TourContext/hook';
import CheckIcon from '@mui/icons-material/Check';
import {
  FloatingContext,
  FloatingFocusManager,
  MiddlewareData,
  UseFloatingReturn,
} from '@floating-ui/react';
import { VibSpinner } from '@vibTheme/components/VibSpinner';
import { Typography } from '@vibTheme/components/_base/Typography';

type Props = PropsWithChildren & {
  floatingStyles: CSSProperties;
  setFloatingRef: UseFloatingReturn['refs']['setFloating'];
  length: number;
  currentStep: number;
  popoverContent: ReactNode;
  middlewareData: MiddlewareData;
  isLoading: boolean;
  context: FloatingContext;
};

export const TourPopover = (props: Props) => {
  const { end, next, previous } = useTourContext();

  return (
    <div
      ref={props.setFloatingRef}
      className="left-[300px] top-[150px] border-2 border-solid border-primary rounded-default z-popover w-[300px] bg-white transition-all"
      style={props.floatingStyles}
    >
      <FloatingFocusManager context={props.context} disabled>
        {/* Exit X */}
        <div className="absolute right-[-4px] top-[-4px]">
          <IconButton onClick={end} disableRipple>
            <CloseIcon />
          </IconButton>
        </div>

        {/* Content */}
        <div className="p-main">{props.popoverContent}</div>

        {/* Footer */}
        <div className="flex justify-between items-center bg-primary">
          <IconButton
            className={`${props.currentStep === 0 && 'invisible'}`}
            onClick={previous}
            disabled={props.isLoading}
          >
            <ArrowBackIcon className="text-white" />
          </IconButton>

          <div className="flex justify-center gap-[5px]">
            {Array.from({ length: props.length }).map((_, index) => (
              <div
                key={index}
                className={`rounded-full w-[7px] h-[7px] bg-white ${
                  index === props.currentStep ? 'bg-white' : 'bg-white/60'
                }`}
              />
            ))}
          </div>

          <IconButton onClick={next} disabled={props.isLoading}>
            {props.currentStep === props.length - 1 ? (
              <CheckIcon className="text-white" />
            ) : (
              <ArrowForwardIcon className="text-white" />
            )}
          </IconButton>
        </div>
      </FloatingFocusManager>
    </div>
  );
};

export const PopoverContentLoading = () => (
  <div className="text-center">
    <VibSpinner size="20px" />
    <Typography className="text-black text-sm pt-sm">Carregando...</Typography>
  </div>
);
