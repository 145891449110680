import { useEffect, useState } from 'react';

export const TourMask = () => {
  const initialHeight = window.innerHeight;
  const [height, setHeight] = useState(initialHeight);

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      const entry = entries[0];
      setHeight(entry.contentRect.height);
    });
    resizeObserver.observe(document.body);
    return () => resizeObserver.unobserve(document.body);
  }, []);

  return (
    <div
      className="absolute min-w-screen min-h-screen w-full backdrop-blur z-mask -mt-navbarHeight"
      style={{
        height: `calc(${height}px + 4rem)`,
      }}
    />
  );
};
