import { TourMask } from '@components/Tour/Mask';
import { PopoverContentLoading, TourPopover } from '@components/Tour/Popover';
import { productCreationTourSteps } from '@components/Tour/ProductCreation/steps';
import useToastContext from '@contexts/ToastContext/hook';
import {
  offset,
  shift,
  useFloating,
  UseFloatingReturn,
} from '@floating-ui/react';
import { Step, TourName } from '@interfaces/tour';
import { useRouter } from 'next/router';
import {
  CSSProperties,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';

type TourContextValue = {
  isRunning: boolean;
  start: (name: TourName) => Promise<void>;
  end: () => void;
  next: () => void;
  previous: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  getRefElementProps: (name: string) => {
    ref: UseFloatingReturn['refs']['setReference'] | null;
    style: Record<string, any>;
  };
};

export const TourContext = createContext<TourContextValue>({
  isRunning: false,
  start: async () => {
    //
  },
  end: () => null,
  next: () => null,
  previous: () => null,
  setCurrentStep: () => null,
  getRefElementProps: () => ({ ref: null, style: {} }),
});

type Props = PropsWithChildren;

export const TourContextProvider = (props: Props) => {
  const [isRunning, setIsRunning] = useState(false);
  const [steps, setSteps] = useState<Step[]>(productCreationTourSteps);
  const [currentStep, setCurrentStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const router = useRouter();
  const toast = useToastContext();

  // Floating UI
  const placement = window.innerWidth <= 768 ? 'bottom' : 'left';
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { refs, floatingStyles, middlewareData, context } = useFloating({
    open: isPopoverOpen,
    onOpenChange: setIsPopoverOpen,
    placement,
    middleware: [offset(10), shift()],
  });

  async function start(name: TourName) {
    const steps = resolveSteps(name);
    await router.push(steps[0].url);

    setIsRunning(true);
    setIsPopoverOpen(true);
    setSteps(steps);
  }

  function end() {
    setIsRunning(false);
  }

  async function next() {
    const step = steps[currentStep];

    try {
      await step.nextCallback?.();
    } catch (err) {
      console.error(err);
      typeof err === 'string' && toast.setContent(err, 'warning');
      return;
    }

    const nextStep = steps[currentStep + 1];
    if (!nextStep) {
      end();
      return;
    }
    let shouldSkip = false;
    if (nextStep.skip) shouldSkip = await nextStep.skip();

    setCurrentStep((step) => {
      const newStep = shouldSkip ? step + 2 : step + 1;
      return newStep > steps.length ? -1 : newStep;
    });
  }

  async function previous() {
    if (currentStep - 1 < 0) return;

    const step = steps[currentStep];
    await step.previousCallback?.();

    const previousStep = steps[currentStep - 1];
    let shouldSkip = false;
    if (previousStep.skip) {
      shouldSkip = await previousStep.skip();
    }

    setCurrentStep((step) => (shouldSkip ? step - 2 : step - 1));
  }

  async function executeCurrentStep() {
    const step = steps[currentStep];
    setIsLoading(true);
    router.pathname !== step.url && (await router.push(step.url));
    setIsLoading(false);
  }

  function getRefElementProps(name: string) {
    const isRefElementActive = steps[currentStep].name === name;

    return {
      ref: isRefElementActive ? refs.setReference : null,
      style: isRefElementActive ? activeRefElementStyles : {},
    };
  }

  useEffect(() => {
    if (isRunning) {
      executeCurrentStep();
      document.addEventListener('keydown', handleTabKeyPress);
    } else {
      setCurrentStep(0);
      document.removeEventListener('keydown', handleTabKeyPress);
    }
  }, [isRunning]);

  useEffect(() => {
    currentStep === -1 && setIsRunning(false);
    isRunning && executeCurrentStep();
  }, [currentStep]);

  const popoverContent =
    isLoading && isRunning ? (
      <PopoverContentLoading />
    ) : (
      steps[currentStep].popoverContent
    );

  return (
    <TourContext.Provider
      value={{
        isRunning,
        start,
        end,
        next,
        previous,
        setCurrentStep,
        getRefElementProps,
      }}
    >
      {isRunning && isPopoverOpen && (
        <>
          <TourPopover
            context={context}
            floatingStyles={floatingStyles}
            length={steps.length}
            currentStep={currentStep}
            popoverContent={popoverContent}
            setFloatingRef={refs.setFloating}
            middlewareData={middlewareData}
            isLoading={isLoading}
          />
          <TourMask />
        </>
      )}

      {props.children}
    </TourContext.Provider>
  );
};

const resolveSteps = (name: TourName) => {
  switch (name) {
    case 'productCreation':
      return productCreationTourSteps;
  }
};

const handleTabKeyPress = (event: KeyboardEvent) => {
  if (event.key === 'Tab') {
    event.preventDefault();
    return false;
  }
};

export const activeRefElementStyles: CSSProperties = {
  position: 'relative',
  zIndex: '500',
};
